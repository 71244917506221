import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Grid } from '@core';

import { AvailablePlatforms, DoubleTextButton, LinkWrapper, Text } from '@components';
import { usePlansValuesContext } from '@context';
import { isClient } from '@shared/helpers';

import { AbsoluteImage } from './components';

const DescriptionComp = ({ children }) => (
  <Text.Body1 as="div" textAlign="left" whiteSpace="pre-line">
    {children}
  </Text.Body1>
);

const CtaTextBlock = ({
  data,
  showAvailablePlatforms,
  titleComponent: TitleComponent = Text.Heading1,
  subtitleComponent: SubtitleComponent = Text.Body2,
  descriptionComponent: DescriptionComponent = DescriptionComp,
  commentComponent: CommentComponent = Text.Body4,
}) => {
  const { t } = useTranslation();

  const {
    isLoading: isLoadingFromContext,
    suggestedPlan: { discountPercentage } = {}, // default value for cms preview to work while loading plans
  } = usePlansValuesContext();

  const isLoading = !isClient || isLoadingFromContext;

  const { title, subtitle, description, buttons, comment } = data.text({
    isLoading,
    discountPercentage,
    t,
  });

  return (
    <Grid.Row>
      <Grid.Col size={12}>
        <TitleComponent $fontFamily="title" textAlign="left">
          {title}
        </TitleComponent>

        {subtitle && <SubtitleComponent mt={8}>{subtitle}</SubtitleComponent>}
      </Grid.Col>

      <Grid.Col size={12} mt={subtitle ? 24 : 48}>
        <DescriptionComponent>{description}</DescriptionComponent>
      </Grid.Col>

      {showAvailablePlatforms && (
        <Grid.Row mt={16}>
          <AvailablePlatforms text={t('common:allSupportedPlatforms')} flexDirection="column" />
        </Grid.Row>
      )}

      {buttons && (
        <Grid.Col size={12} mt={32}>
          <Grid.Row>
            {buttons.map((button, index) => (
              <Grid.Col size={6} pr={16} key={index}>
                <LinkWrapper link={button.link} target="_blank" withParams>
                  {button.title && button.subtitle ? (
                    <DoubleTextButton
                      type="button"
                      size="large"
                      fullWidth
                      title={button.title}
                      subtitle={button.subtitle}
                      {...button.buttonProps}
                    />
                  ) : (
                    <Button type="button" size="large" fullWidth {...button.buttonProps}>
                      {button.title}
                    </Button>
                  )}
                </LinkWrapper>
                {button?.comment && (
                  <Text.Body3 my={8} textAlign="center">
                    {button.comment}
                  </Text.Body3>
                )}
              </Grid.Col>
            ))}
          </Grid.Row>
        </Grid.Col>
      )}

      {comment && (
        <Grid.Col size={12} mt={16}>
          <CommentComponent textAlign="left">{comment}</CommentComponent>
        </Grid.Col>
      )}
    </Grid.Row>
  );
};

const CtaSectionDesktop = ({
  data,
  contentProps,
  showAvailablePlatforms,
  titleComponent,
  subtitleComponent,
  commentComponent,
  descriptionComponent,
  imageComponent,
}) => {
  const { desktop: image } = data.image();

  const ImageComponent = imageComponent?.desktop || AbsoluteImage;

  return (
    <Grid.Container px={40} {...contentProps}>
      <Grid.Row>
        <Grid.Col size={7} maxWidth={588}>
          <CtaTextBlock
            data={data}
            showAvailablePlatforms={showAvailablePlatforms}
            titleComponent={titleComponent}
            subtitleComponent={subtitleComponent}
            commentComponent={commentComponent}
            descriptionComponent={descriptionComponent}
          />
        </Grid.Col>
      </Grid.Row>

      {image && (
        <ImageComponent
          src={image?.src}
          alt={image?.alt}
          loading={image?.loading}
          sizes={image?.sizes}
          {...image?.position}
        />
      )}
    </Grid.Container>
  );
};

export default CtaSectionDesktop;
