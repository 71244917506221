import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Box, Button, Grid, Show } from '@core';
import { AvailablePlatforms, DoubleTextButton, LinkWrapper, Text } from '@components';
import { ImageCore } from '@components/image';
import { usePlansValuesContext } from '@context';
import { isClient } from '@shared/helpers';

const Image = styled(ImageCore)`
  align-self: flex-start; // to keep image aspect ratio on safari
`;

const DescriptionComp = ({ children }) => (
  <Text.Body1 as="div" textAlign="center" whiteSpace="pre-line">
    {children}
  </Text.Body1>
);

const CtaTextBlock = ({
  data,
  showAvailablePlatforms,
  titleComponent: TitleComponent = Text.Heading1,
  subtitleComponent: SubtitleComponent = Text.Body2,
  commentComponent: CommentComponent = Text.Body4,
  descriptionComponent: DescriptionComponent = DescriptionComp,
}) => {
  const { t } = useTranslation();

  const {
    isLoading: isLoadingFromContext,
    suggestedPlan: { discountPercentage } = {}, // default value for cms preview to work while loading plans
  } = usePlansValuesContext();

  const isLoading = !isClient || isLoadingFromContext;

  const { title, subtitle, description, buttons, comment } = data.text({
    isLoading,
    discountPercentage,
    t,
  });

  return (
    <Grid.Row maxWidth={590}>
      <Grid.Col size={12}>
        <TitleComponent $fontFamily="title" textAlign="center">
          {title}
        </TitleComponent>

        {subtitle && (
          <SubtitleComponent textAlign="center" mt={16}>
            {subtitle}
          </SubtitleComponent>
        )}
      </Grid.Col>

      <Grid.Col size={12} mt={subtitle ? 24 : 48}>
        <DescriptionComponent>{description}</DescriptionComponent>
      </Grid.Col>

      {showAvailablePlatforms && (
        <Grid.Col size={12} mt={16} display="flex" justifyContent="center">
          <AvailablePlatforms
            text={t('common:allSupportedPlatforms')}
            iconWrapperProps={{ justifyContent: 'center' }}
          />
        </Grid.Col>
      )}

      {buttons && (
        <Grid.Col mt={24} size={12}>
          <Grid.Row justifyContent="center">
            {buttons.map((button, index) => (
              <Grid.Col
                display="flex"
                justifyContent="center"
                size={{ _: 12, sm: 6 }}
                mb={16}
                px={{ _: 0, sm: 8 }}
                key={index}
              >
                <Box width="100%" maxWidth={280}>
                  <LinkWrapper link={button.link} target="_blank" withParams>
                    {button.title && button.subtitle ? (
                      <DoubleTextButton
                        type="button"
                        size="large"
                        fullWidth
                        title={button.title}
                        subtitle={button.subtitle}
                        {...button.buttonProps}
                      />
                    ) : (
                      <Button type="button" size="large" fullWidth {...button.buttonProps}>
                        {button.title}
                      </Button>
                    )}
                  </LinkWrapper>
                  {button?.comment && (
                    <Text.Body3 my={8} textAlign="center">
                      {button.comment}
                    </Text.Body3>
                  )}
                </Box>
              </Grid.Col>
            ))}
          </Grid.Row>
        </Grid.Col>
      )}

      {comment && (
        <Grid.Col size={12}>
          <CommentComponent textAlign="center">{comment}</CommentComponent>
        </Grid.Col>
      )}
    </Grid.Row>
  );
};

const CtaSectionTablet = ({
  data,
  wrapperProps,
  showAvailablePlatforms,
  titleComponent,
  subtitleComponent,
  commentComponent,
  descriptionComponent,
  imageComponent,
}) => {
  const { mobile, mobileTop, tablet, tabletTop } = data.image();

  const MobileImageComponent = imageComponent?.mobile || Image;
  const TabletImageComponent = imageComponent?.tablet || Image;

  return (
    <Grid.Container>
      {mobileTop && (
        <Show.Mobile width="100%">
          <Grid.Row>
            <Image src={mobileTop.src} alt={mobileTop.alt} {...mobileTop.props} />
          </Grid.Row>
        </Show.Mobile>
      )}
      {tabletTop && (
        <Show.TabletOnly width="100%">
          <Grid.Row>
            <Image src={tabletTop.src} alt={tabletTop.alt} {...tabletTop.props} />
          </Grid.Row>
        </Show.TabletOnly>
      )}

      <Grid.Row justifyContent="center" px={{ _: 24, md: 40 }} {...wrapperProps}>
        <CtaTextBlock
          data={data}
          showAvailablePlatforms={showAvailablePlatforms}
          titleComponent={titleComponent}
          subtitleComponent={subtitleComponent}
          commentComponent={commentComponent}
          descriptionComponent={descriptionComponent}
        />
      </Grid.Row>

      {mobile && (
        <Show.Mobile width="100%">
          <Grid.Row justifyContent="center">
            <MobileImageComponent
              src={mobile?.src}
              alt={mobile?.alt}
              loading={mobile?.loading}
              {...mobile?.props}
            />
          </Grid.Row>
        </Show.Mobile>
      )}
      {tablet && (
        <Show.TabletOnly width="100%">
          <Grid.Row justifyContent="center">
            <TabletImageComponent
              src={tablet?.src}
              alt={tablet?.alt}
              loading={tablet?.loading}
              {...tablet?.props}
            />
          </Grid.Row>
        </Show.TabletOnly>
      )}
    </Grid.Container>
  );
};

export default CtaSectionTablet;
