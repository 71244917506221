import styled from 'styled-components';
import { layout, position, space } from 'styled-system';

import { ImageCore } from '@components/image';

const AbsoluteImage = styled(ImageCore).attrs(({ imgStyle }) => ({
  imgStyle: imgStyle || { objectFit: 'cover' },
}))`
  z-index: -1;
  object-fit: cover;
  position: absolute !important; // !important is for gatsby image to work properly in build
  left: 50%;
  width: 100%;
  transform: translateX(-50%);

  ${space}
  ${layout}
  ${position}
`;

export default AbsoluteImage;
