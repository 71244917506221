import React from 'react';

import { Show } from '@core';
import { SectionContainerSC as SectionContainer } from '@components';

import { CtaSectionDesktop, CtaSectionTablet } from './layouts';

const CTASection = ({ sectionProps, imageComponent, ...props }) => (
  <SectionContainer
    data-section-id="CTA"
    containerProps={{ position: 'relative' }}
    {...sectionProps}
  >
    <Show.TabletLarge>
      <CtaSectionTablet {...props} imageComponent={imageComponent} />
    </Show.TabletLarge>

    <Show.DesktopAndUp>
      <CtaSectionDesktop {...props} imageComponent={imageComponent} />
    </Show.DesktopAndUp>
  </SectionContainer>
);

export default CTASection;
